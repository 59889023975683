import React, { useState } from 'react'
import { PlayIcon } from '@heroicons/react/24/solid'

interface VideoCardProps {
  name: string
  imageUrl: string
  videoUrl: string
  isActive: boolean
  onPlay: () => void
}

interface VideoCardProps {
  name: string
  imageUrl: string
  videoUrl: string
  isActive: boolean
  onPlay: () => void
  onEnd: () => void
}

const VideoCard: React.FC<VideoCardProps> = ({
  name,
  imageUrl,
  videoUrl,
  isActive,
  onPlay,
  onEnd,
}) => {
  const [isPlaying, setIsPlaying] = useState(false)

  const handlePlay = () => {
    onPlay()
    setIsPlaying(true)
  }

  const handleVideoEnd = () => {
    setIsPlaying(false)
    onEnd()
  }

  React.useEffect(() => {
    if (!isActive) {
      setIsPlaying(false)
    }
  }, [isActive])

  return (
    <div
      className='relative bg-black rounded-xl overflow-hidden'
      style={{
        width: '240px',
        aspectRatio: '9/16',
      }}
    >
      {!isPlaying ? (
        <>
          <img
            src={imageUrl}
            alt={name}
            className='w-full h-full object-cover cursor-pointer'
            onClick={handlePlay}
          />
          <div className='absolute bottom-3 left-3' onClick={handlePlay}>
            <div className='rounded-full cursor-pointer'>
              <PlayIcon className='w-6 h-6 text-white' />
            </div>
          </div>
          <div className='absolute bottom-3 right-3'>
            <p className='text-white text-base font-bold'>{name}</p>
          </div>
        </>
      ) : (
        <video
          src={videoUrl}
          controls
          autoPlay
          playsInline
          className='w-full h-full object-cover'
          onEnded={handleVideoEnd}
          onPause={handleVideoEnd}
          // onClick={(e) => {
          //   const video = e.currentTarget
          //   if (video.paused) {
          //     video.play()
          //   } else {
          //     video.pause()
          //   }
          // }}
        />
      )}
    </div>
  )
}
export default VideoCard
