import React, { useState } from 'react'
import VideoCard from './VideoCard'

const videos = [
  {
    name: 'Bomba Guy',
    imageUrl:
      'https://lobbyplatforms.s3.us-west-2.amazonaws.com/tapin-landing-page/1bombaguy.png',
    videoUrl:
      'https://lobbyplatforms.s3.us-west-2.amazonaws.com/tapin-landing-page/bombaguy+tapin.mp4',
  },
  {
    name: 'DAVIE!',
    imageUrl:
      'https://lobbyplatforms.s3.us-west-2.amazonaws.com/tapin-landing-page/2davie.png',
    videoUrl:
      'https://lobbyplatforms.s3.us-west-2.amazonaws.com/tapin-landing-page/davie1.mp4',
  },
  {
    name: 'Doaenel',
    imageUrl:
      'https://lobbyplatforms.s3.us-west-2.amazonaws.com/tapin-landing-page/3doaenel.png',
    videoUrl:
      'https://lobbyplatforms.s3.us-west-2.amazonaws.com/tapin-landing-page/doaenel.mp4',
  },
  {
    name: 'Drututt',
    imageUrl:
      'https://lobbyplatforms.s3.us-west-2.amazonaws.com/tapin-landing-page/4drututt.png',
    videoUrl:
      'https://lobbyplatforms.s3.us-west-2.amazonaws.com/tapin-landing-page/druttut+tapin.mp4',
  },
  {
    name: 'AsianJeff',
    imageUrl:
      'https://lobbyplatforms.s3.us-west-2.amazonaws.com/tapin-landing-page/5aianjeff.png',
    videoUrl:
      'https://lobbyplatforms.s3.us-west-2.amazonaws.com/tapin-landing-page/jeff+tapin.mp4',
  },
  {
    name: 'Layvee',
    imageUrl:
      'https://lobbyplatforms.s3.us-west-2.amazonaws.com/tapin-landing-page/6layvee.png',
    videoUrl:
      'https://lobbyplatforms.s3.us-west-2.amazonaws.com/tapin-landing-page/layvee.mp4',
  },
  {
    name: 'Rav',
    imageUrl:
      'https://lobbyplatforms.s3.us-west-2.amazonaws.com/tapin-landing-page/7rav.png',
    videoUrl:
      'https://lobbyplatforms.s3.us-west-2.amazonaws.com/tapin-landing-page/rav+tapin.mp4',
  },
  {
    name: 'Rival',
    imageUrl:
      'https://lobbyplatforms.s3.us-west-2.amazonaws.com/tapin-landing-page/9rival.png',
    videoUrl:
      'https://lobbyplatforms.s3.us-west-2.amazonaws.com/tapin-landing-page/rival.mp4',
  },
  {
    name: 'Tarzaned',
    imageUrl:
      'https://lobbyplatforms.s3.us-west-2.amazonaws.com/tapin-landing-page/9tarzaned.png',
    videoUrl:
      'https://lobbyplatforms.s3.us-west-2.amazonaws.com/tapin-landing-page/tarzaned+tapin.mp4',
  },
]

const VideoCarouselMobile: React.FC<{ className?: string }> = ({
  className,
}) => {
  const [activeVideoIndex, setActiveVideoIndex] = useState<number | null>(null)

  const handleVideoPlay = (index: number) => {
    setActiveVideoIndex(index)
  }

  const handleVideoEnd = () => {
    setActiveVideoIndex(null) // Reset to null to resume animation
  }

  return (
    <div
      className={`w-full overflow-hidden relative flex flex-col gap-4 ${className}`}
    >
      <h2 className='text-3xl font-bold mb-4 text-white text-center px-4'>
        Your Favorite Influencers Love Tapin
      </h2>

      <div className='relative'>
        <div className='flex gap-4 overflow-x-auto'>
          {[...videos].map((video, index) => (
            <div className='flex-shrink-0' key={index}>
              <VideoCard
                {...video}
                isActive={activeVideoIndex === index}
                onPlay={() => handleVideoPlay(index)}
                onEnd={handleVideoEnd}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default VideoCarouselMobile
